
export namespace OhhEnums {

    export enum LanguageCode {
        Bulgarian = 'bg-bg',
        Chinese_Simplified = 'zh-hans',
        Czech = 'cz-cz',
        Danish = 'da-dk',
        Dutch = 'nl-nl',
        English_US = 'en-us',
        French = 'fr-fr',
        German = 'de-de',
        Italian = 'it-it',
        Japanese = 'ja-jp',
        Norwegian = 'nn-no',
        Portuguese = 'pt-pt',
        Russian = 'ru-ru',
        Spanish = 'es-es',
        Swedish = 'sv-se',
        Thai = 'th-th',
        Korean = 'ko-ko',
        Vietnamese = 'vi'
    }

    export enum MessageSeverity {
        Info = 'info',
        Success = 'success',
        Warn = 'warn',
        Error = 'error'
    }

    export enum PageBreak {
        Medium = 992,
        Tablet = 768,
        Mobile = 576
    }

    export enum RateUnit {
        Hour = 0
    }

    export enum OhhColors {
        SectionBackground = '#f9f9f9'
    }

    export enum HttpStatusCode {
        BadRequest = 400,
        Unauthorized = 401,
        Forbidden = 403,
        FailedDependency = 424,
    }

    export enum ImageType {
        Profile = 0,
        BusinessProfile = 1,
        BusinessGallery = 2,
    }

    export enum EmailType {
        EmailVerify,
        PasswordReset
    }

    export enum PaymentResponseType {
        Cancel,
        Success
    }

    export enum BackgroundCheckStatus {
        NA,
        NotStarted,
        NotCompleted,
        Pending,
        Complete
    }
}
