import { Component, OnDestroy, OnInit } from '@angular/core';
import { Stripe, StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { finalize } from 'rxjs';
import { loadStripe } from '@stripe/stripe-js/pure';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { PaymentsManagerService } from '../../api/payments/payments-manager.service';
import { StripeCreateSession } from '../../api/payments/models/stripe-create-session.model';

@Component({
    selector: 'ohh-payment',
    templateUrl: './payment.component.html',
    styleUrl: './payment.component.less'
})
export class PaymentComponent implements OnInit, OnDestroy {

    protected isBusy = false;

    private stripe: Stripe | null = null;
    private checkout: StripeEmbeddedCheckout;

    constructor(private ohhUserManager: OhhUserManagerService,
                private paymentManager: PaymentsManagerService,
    ) {}

    ngOnInit() {
        setTimeout(async () => {
            // TODO: Do the right thing here when properly implementing checkout form.
            if (!this.ohhUserManager.isUserLoggedIn) return;

            await this.setupEmbeddedStripeCheckout();
        }, 1000)
    }

    ngOnDestroy() {
        if (this.checkout) {
            this.checkout.unmount();
            this.checkout.destroy();
            this.checkout = null;
            this.stripe = null;
        }
    }

    protected remoteStripeCheckout() {
        const user = this.ohhUserManager.ohhUser;

        this.paymentManager.createRemoteCheckoutSession(new StripeCreateSession(user.id, user.email, `${user.firstName} ${user.lastName}`))
            .subscribe({
                next: results => {
                    window.location = results.stripeSessionUrl;
                },
                error: err => {
                    console.log();
                }
            });
    }

    private async setupEmbeddedStripeCheckout() {
        this.isBusy = true;

        loadStripe.setLoadParameters({advancedFraudSignals: false});
        this.stripe = await loadStripe(environment.stripePublishKey);

        const user = this.ohhUserManager.ohhUser;
        this.paymentManager.createEmbeddedCheckoutSession(new StripeCreateSession(user.id, user.email, `${user.firstName} ${user.lastName}`))
            .pipe(finalize(() => {
                setTimeout(() => this.isBusy = false, 1500);
            }))
            .subscribe({
                next: async (response: any) => {
                    try {
                        if (this.stripe && !this.checkout) {
                            this.checkout = await this.stripe.initEmbeddedCheckout({
                                clientSecret: response.clientSecret
                            });

                            this.checkout.mount('#card-element');
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
                error: err => {
                    console.log(err);
                }
            })
    }
}
