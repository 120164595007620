
<div id="ol-provider-signup-container" class="ol-page-container">
	<div style="position: relative;">
		<ohh-section-header [topPadding]="40" [bottomPadding]="40">
			<p>{{ responseText | translate }}</p>
		</ohh-section-header>
	</div>

<!--	<ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">-->
<!--		<ohh-page-footer></ohh-page-footer>-->
<!--	</ohh-full-vw-constrained-container>-->
</div>
