
<div id="ol-provider-signup-container" class="ol-page-container">
	<div style="position: relative;">
		<ohh-section-header [topPadding]="40" [bottomPadding]="40">
			<p>{{ responseText | translate }}</p>

			<div class="ol-sh-heading">
				<span>{{'Submit Your Background Check' | translate | uppercase}}</span>
			</div>
			<div class="ol-sh-body small-margin">
				As a preferred provider, your annual subscription includes the option to submit a background check at no additional cost. Successfully
				completing this process provides your prospective clients with an added layer of trust and confidence in working with you.
			</div>
			<div class="ol-sh-body small-margin">
				Once we receive the all clear from our background check provider, you will receive a <em>Verified</em> Badge on your Provider Card.
			</div>
		</ohh-section-header>

		<ohh-full-vw-constrained-container [bottomPadding]="400">
			<div id="ol-btn-container" style="display: flex; justify-content: center;">
				<button type="submit" class="ol-green-button" (click)="onOrderCheck()" [disabled]="isBusy || user == null">Begin Background Check</button>
			</div>
		</ohh-full-vw-constrained-container>

		<ohh-waiting-indicator
				*ngIf="isBusy"
				[text]="'Initiating background check request' | translate"
				[blurBackground]="true"
				[backgroundColor]="'#0000001a'"
		></ohh-waiting-indicator>

	</div>

	<ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
		<ohh-page-footer></ohh-page-footer>
	</ohh-full-vw-constrained-container>
</div>
