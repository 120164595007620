import ImageType = OhhEnums.ImageType;

import * as loadImage from 'blueimp-load-image';
import { Observable, of } from 'rxjs';
import heic2any from 'heic2any';
import { Injectable } from '@angular/core';
import { OhhEnums } from '../enumerations/ohh.enums';
import { UploadFileResult } from './models/upload-file-result.model';

@Injectable({
    providedIn: 'root'
})
export class ImageAssetService {

    private maxImageSize: number = 2000;

    // =========================================================================================================================================================
    // ctor
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getResizedFileFromBlob(blob: Blob, ohhImageType?: ImageType): Observable<UploadFileResult> {
        const file = this.convertBlobToFile(blob, 'test.jpg');

        switch (ohhImageType) {
            case ImageType.Profile:
                this.maxImageSize = 1000;   // Any smaller and the image looks pixelated.
                break;

            case ImageType.BusinessProfile:
                this.maxImageSize = 1000;   // Any smaller and the image looks pixelated.
                break;

            default:
                this.maxImageSize = 1000;
                break;
        }

        return this.getResizedFile(file);
    }

    getResizedFile(file: File, isReduced = false): Observable<UploadFileResult> {
        if (!file.type.match(/image.*/) && file.name.toLowerCase().indexOf('.heic') === -1) return of(new UploadFileResult(file, 0, 0));

        // @ts-ignore
        return new Observable(async observer => {
            if (file.type === 'image/heic' || file.name.toLowerCase().indexOf('.heic') !== -1) {
                const blobUrl = URL.createObjectURL(file);
                const blobRes = await fetch(blobUrl);
                const blob = await blobRes.blob();
                const jpgBlob = await heic2any({ blob, toType: 'image/jpeg', quality: 0.7 });
                const blobToFile: any = jpgBlob;
                blobToFile.lastModifiedDate = new Date();
                blobToFile.name = 'resized';
                file = <File>blobToFile;
            }
            // Load the image onto a canvas, so we can get a blob to upload (note: this auto-rotates the image)
            loadImage(file, function (canvas, data) {
                    if ('toDataURL' in canvas) {
                        const dataUrl = canvas.toDataURL(file.type, 0.7);
                        const resizedImage = ImageAssetService.dataURLToBlob(dataUrl);
                        observer.next(new UploadFileResult(resizedImage, canvas.width, canvas.height));
                        observer.complete();
                        return;
                    }
                },
                {
                    maxWidth: this.maxImageSize,
                    maxHeight: this.maxImageSize,
                    canvas: true
                }
            );
        });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private static dataURLToBlob(dataURL: any) {
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            const parts2 = dataURL.split(',');
            const contentType2 = parts2[0].split(':')[1];
            const raw2 = parts2[1];

            return new Blob([raw2], { type: contentType2 });
        }

        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;

        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    private convertBlobToFile(blob: Blob, fileName: string): File {
        return new File([blob], fileName, { type: blob.type });
    }

    // private getImageInfoFromBlob(blob: Blob): Promise<{ width: number; height: number; size: number }> {
    //     const size = blob.size; // Get file size in bytes
    //
    //     // Return a promise that resolves with the image dimensions
    //     return new Promise<{ width: number; height: number; size: number }>((resolve, reject) => {
    //         const img = new Image();
    //         const objectUrl = URL.createObjectURL(blob);
    //
    //         img.onload = () => {
    //             resolve({ width: img.width, height: img.height, size });
    //             URL.revokeObjectURL(objectUrl); // Clean up the object URL
    //         };
    //
    //         img.onerror = (error) => {
    //             reject(error);
    //             URL.revokeObjectURL(objectUrl); // Clean up on error
    //         };
    //
    //         img.src = objectUrl; // Set the image source
    //     });
    // }
}
