<div id="ol-get-found-container" class="ol-page-container">

    <ohh-full-vw-constrained-container [backgroundColor]="'#8dc73f'">
        <div class="aspect-ratio-box">
            <div *ngIf="showFirstImage" [@fadeInOut] class="aspect-ratio-box-inner">
                <img class="prevent-select" src="../../../assets/images/ohh-woman_tp_r.png" alt="">
            </div>
            <div *ngIf="!showFirstImage" [@fadeInOut] class="aspect-ratio-box-inner" >
                <img class="prevent-select" src="../../../assets/images/ohh-man_tp_r.png" alt="">
            </div>

            <div id="ol-hero">
                <div id="ol-hero-text">
                    <div id="ol-block-1" class="prevent-select">
                        <div>Grow Your</div>
                        <div>Business With Our</div>
                    </div>
                    <div id="ol-block-2" class="prevent-select">
                        <div>Entrepreneurs</div>
                        <div>Advertising Hub!</div>
                    </div>
                    <div id="ol-gf-bk-btn" class="ol-btn" (click)="onGetFound()">
                        <span class="prevent-select">Get Found, Get Booked.</span>
                    </div>
                    <div id="ol-block-3" class="prevent-select">
                        <span>
                            <span class="ol-font-semi-bold"><em>{{'OHH.APP_NAME' | translate}}</em></span>
                            connects you to customers <span class="ol-font-semi-bold">who need your services at their homes,</span> and in the community.
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div id="ol-reasons-container" class="prevent-select" >
            <div class="ol-reason"><fa-icon icon="check"></fa-icon>Boost Your <strong>Visibility</strong></div>
            <div class="ol-reason"><fa-icon icon="check"></fa-icon>Showcase Your <strong>Skills</strong></div>
            <div class="ol-reason"><fa-icon icon="check"></fa-icon>Skip the <strong>Commissions</strong></div>
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [isPadded]="false" [bottomPadding]="87">
        <div id="ol-prof-svcs-container">
            <div class="ol-ps-50 prevent-select">
                <img src="../../../assets/images/interview.jpg" alt="">
            </div>
            <div id="ol-ps-txt-container" class="ol-ps-50">
                <div id="ol-ps-txt" class="prevent-select">
                    Promote your business to people who are looking for reliable professional services.
                </div>
                <div class="ol-btn ol-std-btn green ol-green-btn-text" (click)="onGetFound()">
                    <span class="prevent-select">Get Early Access</span>
                </div>
            </div>
        </div>
    </ohh-full-vw-constrained-container>


<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        <div id="ol-images-container" class="prevent-select">-->
<!--            <div class="ol-ps-50 left" >-->
<!--                <img src="../../../assets/images/wording.jpg" alt="">-->
<!--            </div>-->
<!--            <div class="ol-ps-50 right">-->
<!--                <img src="../../../assets/images/handyman.jpg" alt="">-->
<!--            </div>-->
<!--        </div>-->
<!--    </ohh-full-vw-constrained-container>-->

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div class="ol-4u ol-flex-col-center prevent-select">
            <div>We Created <span class="ol-green-text"><em>{{'OHH.APP_NAME' | translate}}</em></span> For</div> <div>Independent Entrepreneurs Like You.</div>
        </div>
        <div id="ol-create-ap" class="prevent-select">
            Create Your Ad Profile, Highlight Your Specialties, And Get Noticed.
        </div>
        <div id="ol-phones">
            <img src="../../../assets/images/phone-mark.png" alt="" (click)="onViewServices()">
            <img src="../../../assets/images/phone-sean-2.png" alt="" (click)="onViewServices()">
            <img src="../../../assets/images/phone-laura-2.png" alt="" (click)="onViewServices()">
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div id="ol-mem-container" class="ol-flex-col-center prevent-select">
            <div class="ol-mem-hl-header">
                Membership Designed to <span class="ol-green-text">Grow</span> Your Business
            </div>
            <div>
                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Exclusive Access</div>
                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>A Unique Video-Directory</div>
                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Keep 100% Of Your Earnings</div>
				<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Connect Directly With Local Customers</div>
				<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Detailed Ad-Profiles To Promote Your Expert Services</div>
				<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Increase Visibility With Social Collaborations & Promotions</div>
                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Get Web Pages & Ads Dedicated to You</div>
<!--                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Gain access to Ads Posted by Clients Looking For Help</div>-->
                <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Boost your website's SEO by Linking it to ours</div>
            </div>
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div style="align-items: center; display: flex; flex-direction: column;">
            <div id="ol-hiw-header" class="ol-mem-hl-header prevent-select">
                Here's How it Works
            </div>

            <ohh-image-callout-container class="ol-icc">
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-profile.svg" alt="">
                    <p class="ol-icc-heading">CREATE YOUR <strong>PROFILE</strong></p>
                    <div class="ol-icc-body">
						Highlight your services, add photos to your gallery, and link customer reviews.
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-key.svg" alt="">
                    <p class="ol-icc-heading">GET <strong>VERIFIED</strong></p>
                    <div class="ol-icc-body">
						Run a simple background check to receive a verified badge.<br>(Coming Soon)
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-laptop.svg" alt="">
                    <p class="ol-icc-heading">SHOWCASE <strong>YOUR SERVICES</strong></p>
                    <div class="ol-icc-body">
						We’ll Help You Optimize And Maximize Visibility On Your Profile.
                    </div>
                </ohh-image-callout-card>
            </ohh-image-callout-container>
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div id="ol-advert-heading" class="ol-4u ol-flex-col-center prevent-select">
            <div>Welcome To A New Platform For Entrepreneurs.</div>
            <div>Become A Founding Member And Advertise Your Business For <span class="ol-green-text">FREE!</span></div>
        </div>

        <div id="membership-options">
            <div class="mem-option premium" style="justify-content: space-between;">
				<div>
                <div class="mem-opt-title prevent-select">
                    Free<br>Membership
                </div>
					<div class="mem-opt-body prevent-select">
						<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Members Only</div>
						<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Increase Exposure to Your Local Community</div>
						<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Use Your Ad-Profile To Showcase Your Business</div>
						<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Get Listed In The Category Of Your Choice</div>
					</div>
				</div>
                <div class="mem-pln-btn ol-btn ol-std-btn" (click)="onGetFound()">
                    <div class="prevent-select">Get This Plan</div>
                    <div class="prevent-select"><em>Free</em></div>
                </div>
            </div>
            <div class="mem-option elite">
                <div class="mem-opt-title prevent-select">
                    Preferred<br>Membership
                </div>
                <div class="mem-opt-body prevent-select">
                    <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Enjoy Free Membership Benefits +</div>
					<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Preferred Placement</div>
					<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Verified Badge With Background Check</div>
					<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Social Media Collaborations</div>
					<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Newsletter Shout-outs</div>
					<div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Access To Classified Listings</div>
<!--                    <div class="ol-mem-value"><fa-icon icon="check"></fa-icon>Get listed in multiple categories</div>-->
                </div>
                <div class="mem-pln-btn ol-btn ol-std-btn" (click)="onGetFound()">
                    <div class="prevent-select">Get This Plan</div>
                    <div class="prevent-select"><em>$29/yr</em></div>
                </div>
            </div>

<!--            <div id="free-mem-banner" class="prevent-select">-->
<!--                <div id="fmb-header">FREE Membership For Six Months</div>-->
<!--                <div id="fmb-note">Advertising prices will start at $39 per month after six months for your full page ad.</div>-->
<!--            </div>-->
        </div>
    </ohh-full-vw-constrained-container>

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        Secure a Top Spot...-->
<!--    </ohh-full-vw-constrained-container>-->

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div class="ol-4u ol-flex-col-center prevent-select">
            <div><span class="ol-green-text"><em>{{'OHH.APP_NAME' | translate}}</em></span> Puts You</div>
            <div>In The Center Of Searching Customers.</div>
        </div>

        <img id="ol-collage" src="../../../assets/images/provider-phone-collage.png" alt="">

        <ohh-image-callout-container class="ol-icc">
            <ohh-image-callout-card class="ol-icc-three-up">
                <p class="ol-icc-heading"><strong>GET NOTICED</strong></p>
                <div class="ol-icc-body">
                    Your profile video allows you to show off who you are and what you do best.
                </div>
            </ohh-image-callout-card>
            <ohh-image-callout-card class="ol-icc-three-up">
                <p class="ol-icc-heading"><strong>WE BRING THE CLIENTS</strong></p>
                <div class="ol-icc-body">
                    Connect directly with your client with no additional fees.
                </div>
            </ohh-image-callout-card>
            <ohh-image-callout-card class="ol-icc-three-up">
                <p class="ol-icc-heading"><strong>BOOST VISIBILITY</strong></p>
                <div class="ol-icc-body pre">
                    Expand your reach leveraging our social networking & marketing campaigns.
                </div>
            </ohh-image-callout-card>
        </ohh-image-callout-container>
    </ohh-full-vw-constrained-container>

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        Stand out from the crowd...-->
<!--    </ohh-full-vw-constrained-container>-->

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        It would cost you thousands...-->
<!--    </ohh-full-vw-constrained-container>-->

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        Cost of advertising spanner...-->
<!--    </ohh-full-vw-constrained-container>-->

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        Another Secure your spot button...-->
<!--    </ohh-full-vw-constrained-container>-->

    <ohh-full-vw-constrained-container [isPadded]="true">
        <div id="ol-btm-img-carousel" style="display: flex; justify-content: center; flex-wrap: wrap;">
            <img src="../../../assets/images/pro-carousel-01.png" alt="">
            <img src="../../../assets/images/pro-carousel-02.png" alt="">
            <img src="../../../assets/images/pro-carousel-03.png" alt="">
            <img src="../../../assets/images/pro-carousel-04.png" alt="">
            <img src="../../../assets/images/pro-carousel-05.png" alt="">
        </div>

        <div class="ol-4u ol-flex-col-center prevent-select">
            <div><span class="ol-green-text"><em>{{'OHH.APP_NAME' | translate}}</em></span> helps independent entrepreneurs to increase their marketing efforts.</div>
        </div>

        <div class="ol-btn ol-std-btn green ol-green-btn-text" style="margin: 0 auto" (click)="onGetFound()">
            <span class="prevent-select">Get Started</span>
        </div>

    </ohh-full-vw-constrained-container>

<!--    <ohh-full-vw-constrained-container [isPadded]="true">-->
<!--        Ohh Look Advantages and FAQs accordions...-->
<!--    </ohh-full-vw-constrained-container>-->

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>

</div>


