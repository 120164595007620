import BackgroundCheckStatus = OhhEnums.BackgroundCheckStatus;
import ImageType = OhhEnums.ImageType;

import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhImageInfo } from './ohh-image-info.model';

export class OhhUser {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.lastLogin) this.lastLogin = new Date(json.lastLogin);
            if (json.images) this.images = json.images.map((image: OhhImageInfo) => new OhhImageInfo(image));
            if (json.clearChecksOrderDate) this.clearChecksOrderDate = new Date(json.clearChecksOrderDate);
            if (json.clearChecksCompletedDate) this.clearChecksCompletedDate = new Date(json.clearChecksCompletedDate);
        }
    }

    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    lastLogin: Date;
    isProvider = false;
    isPreferred = false;
    images: OhhImageInfo[] = [];
    clearChecksReportKey: string;
    clearChecksReportStatus: string;
    clearChecksIsFlagged: boolean = false;
    clearChecksOrderDate: Date;
    clearChecksCompletedDate: Date;

    get profileImage(): OhhImageInfo {
        return this.images?.find((image: OhhImageInfo) => image.type == ImageType.Profile);
    }

    get backgroundCheckStatus(): BackgroundCheckStatus {
        if (!this.isProvider || !this.isPreferred) return BackgroundCheckStatus.NA;

        // User is Preferred Provider

        // Not started
        if (this.clearChecksReportKey == null || this.clearChecksReportStatus === '') { return BackgroundCheckStatus.NotStarted; }

        // Started, but not complete
        if (this.clearChecksReportStatus == null || this.clearChecksReportStatus == '') { return BackgroundCheckStatus.NotCompleted; }

        // Pending
        if (this.clearChecksReportStatus != 'C') { return BackgroundCheckStatus.Pending; }

        if (this.clearChecksReportStatus == 'C') { return BackgroundCheckStatus.Complete; }

        return BackgroundCheckStatus.NA;
    }

    get isVerified(): boolean {
        if (!this.isProvider || !this.isPreferred) return false;

        return this.backgroundCheckStatus == BackgroundCheckStatus.Complete && !this.clearChecksIsFlagged;
    }
}
