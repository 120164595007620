export class UploadFileResult {
    constructor(fileOrUrl: any, width?: number, height?: number) {
        this.fileOrUrl = fileOrUrl;
        this.width = width;
        this.height = height;
    }

    fileOrUrl: any;
    width?: number;
    height?: number;

}
