import ImageType = OhhEnums.ImageType;

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddImageModel } from '../../../api/image/models/add-image.model';
import { AzureBlobService } from '../../../common/services/azure-blob.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CropperImageInfo } from '../../../shared/components/image-cropper/models/cropper-image-info.model';
import { EditableComponent } from '../../../shared/components/editable-component';
import { environment } from '../../../../environments/environment';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { ImageManagerService } from '../../../api/image/image-manager.service';
import { ImageReference } from '../../../api/image/models/image-reference.model';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhUser } from '../../../api/ohh-user/models/ohh-user.model';
import { Util } from '../../../common/Utils/util';

@UntilDestroy()
@Component({
    selector: 'ohh-customer-profile',
    templateUrl: './customer-profile.component.html',
    styleUrl: './customer-profile.component.less'
})
export class CustomerProfileComponent extends EditableComponent implements OnInit {
    @ViewChild('fileInput') fileInput!: ElementRef;

    protected readonly placeholderImageUrl = '../../../assets/images/ohh-provider-ph.png'
    protected readonly OhhEnums = OhhEnums;
    protected readonly ImageType = ImageType;

    @Output() submitForm = new EventEmitter<void>();

    @Output() formHasChangesChange = new EventEmitter<boolean>();

    private _formHasChanges = false;
    @Input() set formHasChanges(value: boolean) {
        if (value != null && value != this._formHasChanges) {
            this.formHasChangesChange.next(value);
        }
        this._formHasChanges = value;
    }

    get formHasChanges(): boolean {
        return this._formHasChanges;
    }

    @Input() formGroup!: FormGroup;
    @Input() parentFormGroup!: FormGroup;
    @Input() ohhUser: OhhUser;
    @Input() activeIndex = 0;

    private _saveSuccess = false;
    @Input() set saveSuccess(value: boolean) {
        if (value != true) return;

        this.initialFormValues = this.formGroup.getRawValue();
        this._saveSuccess = false;
    }

    get saveSuccess(): boolean {
        return this._saveSuccess;
    }

    protected profileImageUri: string;
    protected initFile: File;
    protected showImageSelectionDlg = false;
    protected profileImageType = ImageType.Profile;
    protected showImageCropperSpinner = false;

    private initialFormValues: any;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private blobService: AzureBlobService,
                private broadcastService: BroadcastService,
                private fb: FormBuilder,
                private imageManager: ImageManagerService,
                private logger: ErrorLoggingService,
    ) {
        super();
    }

    override ngOnInit() {
        super.ngOnInit();

        this.broadcastService.cancelComponentChanges
            .pipe(
                untilDestroyed(this),
                filter((value) => value != 'CustomerProfileComponent')
            )
            .subscribe(() => this.onCancel(false));

        this.formGroup.addControl('firstName', this.fb.control(this.ohhUser?.firstName, [Validators.required]));
        this.formGroup.addControl('lastName', this.fb.control(this.ohhUser?.lastName, [Validators.required]));
        this.formGroup.addControl('email', this.fb.control({ value: this.ohhUser?.email, disabled: true }, [Validators.required, Validators.email]));
        this.formGroup.addControl('phoneNumber', this.fb.control(this.ohhUser?.phoneNumber || ''));
        this.formGroup.addControl('address1', this.fb.control(this.ohhUser?.address1 || ''));
        this.formGroup.addControl('city', this.fb.control(this.ohhUser?.city || ''));
        this.formGroup.addControl('state', this.fb.control(this.ohhUser?.state || ''));
        this.formGroup.addControl('postalCode', this.fb.control(this.ohhUser?.postalCode || ''));

        this.initialFormValues = this.formGroup.getRawValue();

        this.getComponentData();
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    // Called by parent form to inquire if this component has any pending changes.
    hasChanges(): boolean {
        return this.formGroup.dirty
            && this.formGroup.valid
            && JSON.stringify(this.formGroup.getRawValue()) !== JSON.stringify(this.initialFormValues);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.formGroup.valid) {
            this.submitForm.emit();
        } else {
            this.formGroup.markAllAsTouched();  // Mark all controls as touched to show validation errors
        }
    }

    protected onDragOver(event: DragEvent) {
        event.preventDefault(); // Prevent default behavior to allow drop
        event.stopPropagation();
        console.log('Drag over event');
    }

    protected onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        console.log('Drag leave event');
    }

    protected onDrop(event: DragEvent, imageType = ImageType.Profile) {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            this.initFile = files[0];
            this.showImageSelectionDlg = true;
        }
    }

    protected onUploadFile() {
        this.fileInput.nativeElement.click();
    }

    protected onCancel(propagateEvent = true) {
        this.formGroup.reset(this.initialFormValues);

        if (propagateEvent) {
            this.broadcastService.cancelComponentChanges.next('CustomerProfileComponent');
        }
    }

    protected onFileSelected(event: Event, fileInput: HTMLInputElement) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            this.initFile = input.files[0];
            this.profileImageType = ImageType.Profile;
            this.showImageSelectionDlg = true;

            fileInput.value = "";
        }
    }

    async onSaveProfileImage(blobInfo: CropperImageInfo) {
        // Retrieve any old profile image references from the db.
        this.imageManager.getProfilePictureRefs(this.profileImageType)
            .subscribe({
                next: async (refs: ImageReference[]) => {
                    // Delete each legacy blob (from azure) and image reference (from the database).
                    for (const ref of refs) {
                        // Delete the physical blob and its the database reference
                        try {
                            await this.blobService.deleteBlob(`${this.ohhUser.id}/${ref.fileName}`, () => {
                                this.imageManager.deleteImageReference(ref.id).subscribe();
                            });
                        } catch (error: any) {
                            // Not ideal, but not fatal. Should never occur, but if it does we want to ignore it.
                            // TODO: Log this.
                            if (error?.message.includes('specified blob does not exist')) {
                                this.imageManager.deleteImageReference(ref.id).subscribe();
                            }
                        }
                    }

                    const uniqueBlobName = Util.generateGUID();

                    try {
                        // Upload the new image to azure blob storage
                        await this.blobService.uploadBlob(`${this.ohhUser.id}/${uniqueBlobName}`, blobInfo.blob, () => {
                            // If blob upload succeeds, add a db reference.
                            this.imageManager.addImageReference(new AddImageModel(uniqueBlobName, blobInfo.blobName, this.profileImageType))
                                .subscribe({
                                    next: response => {
                                        if (response.uri != null) {
                                            this.profileImageUri = `${environment.ohhCdnUrl}/${response.uri}`;
                                            this.broadcastService.profilePictureChanged.next({ uri: this.profileImageUri });
                                            this.showImageSelectionDlg = false;
                                        }
                                    },
                                    error: err => {
                                        // TODO: Notify user of failure ??
                                        this.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: err }));
                                    }
                                })
                        });
                    }
                    catch (error) {
                        this.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: error }));
                    }
                    finally {
                        this.showImageCropperSpinner = false;
                    }

                },
                error: err => {
                    // TODO: Notify user of failure ??
                    this.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: err }));
                }
            })
    }

    // =========================================================================================================================================================
    // helper Methods
    // =========================================================================================================================================================

    protected isFormDirtyAndValid(): boolean {
        return this.parentFormGroup?.dirty && this.parentFormGroup?.valid;
    }

    private getComponentData() {
        const sources: Observable<any>[] = [
            this.imageManager.getProfilePictureUri().pipe(take(1))
        ];

        forkJoin(sources)
            .subscribe({
                next: ([profileUri, bizProfileUri]) => {
                    this.profileImageUri = profileUri != null ? `${environment.ohhCdnUrl}/${profileUri}` : null;
                }, error: err => {
                    this.profileImageUri = null;
                    this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                }
            });
    }

    // =========================================================================================================================================================
    // Field Validation
    // =========================================================================================================================================================

    protected fieldIsInvalid(fieldName: string) {
        return this.formGroup.get(fieldName).invalid && this.formGroup.get(fieldName).touched && this.formGroup.get(fieldName).dirty;
    }
}
