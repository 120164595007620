import ImageType = OhhEnums.ImageType;
import BackgroundCheckStatus = OhhEnums.BackgroundCheckStatus;
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, finalize, forkJoin, Observable, take } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddImageModel } from '../../../api/image/models/add-image.model';
import { AzureBlobService } from '../../../common/services/azure-blob.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CanChildDeactivate } from '../../../common/guards/ICanChildDeactivate';
import { ConfirmationService } from 'primeng/api';
import { ContactMethodManagerService } from '../../../api/contact-method/contact-method-manager.service';
import { CropperImageInfo } from '../../../shared/components/image-cropper/models/cropper-image-info.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EditableComponent } from '../../../shared/components/editable-component';
import { environment } from '../../../../environments/environment';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { ImageManagerService } from '../../../api/image/image-manager.service';
import { ImageReference } from '../../../api/image/models/image-reference.model';
import { LocationManagerService } from '../../../api/location/location-manager.service';
import { OhhContactMethod } from '../../../api/provider/models/ohh-contact-method.model';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhImageInfo } from '../../../api/ohh-user/models/ohh-image-info.model';
import { OhhPaymentMethod } from '../../../api/provider/models/ohh-payment-method.model';
import { OhhProvider } from '../../../api/provider/models/ohh-provider.model';
import { OhhProviderInfo } from '../../../api/provider/models/ohh-provider-info.model';
import { OhhProviderLocation } from '../../../api/provider/models/ohh-provider-location.model';
import { OhhServiceCategory } from '../../../api/service-category/models/ohh-service-category.model';
import { OhhUser } from '../../../api/ohh-user/models/ohh-user.model';
import { PaymentMethodManagerService } from '../../../api/payment-method/payment-method-manager.service';
import { Router } from '@angular/router';
import { ServiceCategoryManager } from '../../../api/service-category/service-category-manager.service';
import { Util } from '../../../common/Utils/util';


@UntilDestroy()
@Component({
    selector: 'ohh-business-profile',
    templateUrl: './business-profile.component.html',
    styleUrl: './business-profile.component.less'
})
export class BusinessProfileComponent extends EditableComponent implements OnInit, CanChildDeactivate {
    @ViewChild('fileInput') fileInput!: ElementRef;
    @ViewChild('bizFileInput') bizFileInput!: ElementRef;
    @ViewChild('galleryFileInput') galleryFileInput!: ElementRef;

    protected readonly placeholderImageUrl = '../../../assets/images/ohh-provider-ph.png'
    protected readonly OhhEnums = OhhEnums;
    protected readonly ImageType = ImageType;
    protected isMobile: boolean = false;

    private readonly MaxGalleryImages = 10;

    @Output() submitForm = new EventEmitter<void>();

    @Output() formHasChangesChange = new EventEmitter<boolean>();

    private _formHasChanges = false;
    @Input() set formHasChanges(value: boolean) {
        if (value != null && value != this._formHasChanges) {
            setTimeout(() => this.formHasChangesChange.next(value));
        }
        this._formHasChanges = value;
    }

    get formHasChanges(): boolean {
        return this._formHasChanges;
    }

    @Input() formGroup!: FormGroup;
    @Input() parentFormGroup!: FormGroup;
    @Input() isParentFormValid: boolean = false;
    @Input() user: OhhUser;

    private _provider: OhhProvider
    @Input() set provider(value: OhhProvider) {
        if (value == null) return;
        this._provider = value;

        this.updateGalleryImagesArray()
    }

    get provider(): OhhProvider {
        return this._provider;
    }

    private _providerInfo: OhhProviderInfo;
    @Input() set providerInfo(value: OhhProviderInfo) {
        this._providerInfo = value;
        this.setFormGroupValues();
    }

    get providerInfo(): OhhProviderInfo {
        return this._providerInfo;
    }

    private _saveSuccess = false;
    @Input() set saveSuccess(value: boolean) {
        if (value != true) return;

        this.initialFormValues = this.formGroup.getRawValue();
        this._saveSuccess = false;
    }

    get saveSuccess(): boolean {
        return this._saveSuccess;
    }

    private workingLocations: string[] = []
    // set workingLocations(value: string[]) {
    //     this._workingLocations = value;
    //     this.setAllLocationsCheckboxState();
    // }
    //
    // get workingLocations(): string[] {
    //     return this._workingLocations;
    // }

    protected bizProfileImageUri: string;
    protected initFile: File;
    protected showImageSelectionDlg = false;
    protected profileImageType = ImageType.Profile;
    protected serviceCategories: OhhServiceCategory[] = [];
    protected selectedCategory: OhhServiceCategory;
    protected paymentMethods: OhhPaymentMethod[] = [];
    protected contactMethods: OhhContactMethod[] = [];
    protected locations: OhhProviderLocation[] = [];
    protected galleryImages: any[] = [{}];
    protected responsiveOptions: any[] | undefined;
    protected ohhCdnUrl: string;
    protected areAllLocationsSelected: boolean = null;
    protected showImageCropperSpinner = false;
    protected showBgCheckBtn = false;
    protected bgCheckStatus: string = null;
    protected bgStatusColor: string = 'black';

    private isFormInitialized = false;
    private initialFormValues: any;
    private acceptedPaymentMethods: string[] = [];
    private acceptedContactMethods: string[] = [];
    private providerCategories: string[] = [];
    private providerSubCategories: string[] = []
    private isImagePreviewOpen = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private blobService: AzureBlobService,
                private broadcastService: BroadcastService,
                private categoryManager: ServiceCategoryManager,
                private cdRef: ChangeDetectorRef,
                private confirmationService: ConfirmationService,
                private contactMethodManager: ContactMethodManagerService,
                private fb: FormBuilder,
                private imageManager: ImageManagerService,
                private locationManager: LocationManagerService,
                private logger: ErrorLoggingService,
                private paymentMethodManager: PaymentMethodManagerService,
                private router: Router,
                private deviceService: DeviceDetectorService
    ) {
        super();
    }

    override ngOnInit() {
        super.ngOnInit();
        this.isMobile = this.deviceService.isMobile();
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.ohhCdnUrl = environment.ohhCdnUrl;

        this.broadcastService.cancelComponentChanges
            .pipe(
                untilDestroyed(this),
                filter((value) => value != 'BusinessProfileComponent')
            )
            .subscribe(() => this.onCancel(false));

        this.formGroup.addControl('businessName', this.fb.control(this.providerInfo?.businessName));
        this.formGroup.addControl('primaryService', this.fb.control(this.providerInfo?.service, Validators.maxLength(100)));
        this.formGroup.addControl('businessPhone', this.fb.control(this.providerInfo?.businessPhone));
        this.formGroup.addControl('businessEmail', this.fb.control(this.providerInfo?.businessEmail));
        this.formGroup.addControl('website', this.fb.control(this.providerInfo?.websiteUrl));
        this.formGroup.addControl('contactForRate', this.fb.control(this.providerInfo?.contactForRate || false));
        this.formGroup.addControl('rate', this.fb.control(this.providerInfo?.rate || 0));
        this.formGroup.addControl('insured', this.fb.control(this.providerInfo?.isInsured || false));
        this.formGroup.addControl('specialties', this.fb.control(this.providerInfo?.specialties || []));
        this.formGroup.addControl('introduction', this.fb.control(this.providerInfo?.introduction));
        this.formGroup.addControl('experience', this.fb.control(this.providerInfo?.experience));
        this.formGroup.addControl('services', this.fb.control(this.providerInfo?.services));
        this.formGroup.addControl('whyHire', this.fb.control(this.providerInfo?.whyHire));
        this.formGroup.addControl('instaUrl', this.fb.control(this.providerInfo?.instaUrl));
        this.formGroup.addControl('facebookUrl', this.fb.control(this.providerInfo?.facebookUrl));
        this.formGroup.addControl('linkedInUrl', this.fb.control(this.providerInfo?.linkedInUrl));
        this.formGroup.addControl('youTubeUrl', this.fb.control(this.providerInfo?.youTubeUrl));
        this.formGroup.addControl('tikTokUrl', this.fb.control(this.providerInfo?.tikTokUrl));

        this.acceptedContactMethods = this.getAcceptedContactMethods();
        this.formGroup.addControl('contactMethods', this.fb.control(this.acceptedContactMethods));

        this.acceptedPaymentMethods = this.getAcceptedPaymentMethods();
        this.formGroup.addControl('paymentMethods', this.fb.control(this.acceptedPaymentMethods));

        this.providerCategories = this.getCategories()
        // this.formGroup.addControl('category', this.fb.control(this.providerInfo?.categories.length > 0 ? this.providerCategories[0]?.toLowerCase() : null, [Validators.required]));
        this.formGroup.addControl('category', this.fb.control(this.providerInfo?.categories.length > 0 ? this.providerCategories[0]?.toLowerCase() : null));

        this.providerSubCategories = this.getSubCategories()
        // this.formGroup.addControl('subCategories', this.fb.control(this.providerSubCategories, [Validators.required]));
        this.formGroup.addControl('subCategories', this.fb.control(this.providerSubCategories));

        this.workingLocations = this.getWorkingLocations()
        this.formGroup.addControl('locations', this.fb.control(this.workingLocations));

        // this.formGroup.addControl('email', this.fb.control({ value: this.ohhUser?.email, disabled: true }, [Validators.required, Validators.email]));
        // this.formGroup.addControl('phoneNumber', this.fb.control(this.ohhUser?.phoneNumber || ''));
        // this.formGroup.addControl('address1', this.fb.control(this.ohhUser?.address1 || ''));
        // this.formGroup.addControl('city', this.fb.control(this.ohhUser?.city || ''));
        // this.formGroup.addControl('state', this.fb.control(this.ohhUser?.state || ''));
        // this.formGroup.addControl('postalCode', this.fb.control(this.ohhUser?.postalCode || ''));

        // React to changes in the `contactForPricing` control
        this.formGroup.get('contactForRate')?.valueChanges.subscribe(value => {
            this.toggleRateField(value);
            this.cdRef.detectChanges();  // Avoid ExpressionChangedAfterItHasBeenCheckedError error.
        });

        // Avoid ExpressionChangedAfterItHasBeenCheckedError error.
        this.formGroup.get('contactMethods')?.valueChanges.subscribe(value => this.cdRef.detectChanges());
        this.formGroup.get('paymentMethods')?.valueChanges.subscribe(value => this.cdRef.detectChanges());
        this.formGroup.get('subCategories')?.valueChanges.subscribe(value => this.cdRef.detectChanges());

        this.formGroup.get('category')?.valueChanges.subscribe(selectedCategoryId => {
            this.selectedCategory = this.serviceCategories.find(c => c.id == selectedCategoryId);

            this.providerSubCategories = [];
            this.formGroup.get('subCategories').setValue(this.providerSubCategories);
        })

        this.formGroup.get('locations')?.valueChanges.subscribe(selectedLocations => {
            this.setAllLocationsCheckboxState(selectedLocations);
        });

        this.initialFormValues = this.formGroup.getRawValue();

        this.getComponentData();
        this.setBackgroundCheckStatus();

        this.isFormInitialized = true;
    }

    override canDeactivate(): boolean {
        if (this.isImagePreviewOpen) {
            const overlay = document.querySelector('.p-image-mask');
            if (overlay) {
                overlay.dispatchEvent(new Event('click')); // Simulate a close action
            }

            return false;
        }

        return super.canDeactivate()
    }

    private getAcceptedPaymentMethods() {
        if (this.providerInfo == null || this.providerInfo.paymentMethods == null) return [];

        return this.providerInfo?.paymentMethods.map(m => m.id);
    }

    private getAcceptedContactMethods() {
        if (this.providerInfo == null || this.providerInfo.contactMethods == null) return [];

        return this.providerInfo?.contactMethods.map(m => m.id);
    }

    private getCategories() {
        if (this.providerInfo == null || this.providerInfo.categories == null) return [];

        return this.providerInfo?.categories.map(c => c.id)
    }

    private getSubCategories() {
        if (this.providerInfo == null || this.providerInfo.categories == null) return [];

        return this.providerInfo?.categories.length > 0 ? this.providerInfo?.categories.flatMap(cat => cat.subCategories.map(c => c.id)) : [];
    }

    private getWorkingLocations() {
        if (this.providerInfo == null || this.providerInfo.locations == null) return [];

        return this.providerInfo?.locations.map(m => m.id);
    }

    private setFormGroupValues() {
        if (!this.isFormInitialized) {
            setTimeout(this.setFormGroupValues.bind(this), 100);
            return;
        }

        if (this.providerInfo == null) return;

        this.acceptedPaymentMethods = this.getAcceptedPaymentMethods();
        this.acceptedContactMethods = this.getAcceptedContactMethods();
        this.providerCategories = this.getCategories();
        this.providerSubCategories = this.getSubCategories();
        this.workingLocations = this.getWorkingLocations()

        this.formGroup.setValue({
            businessName: this.providerInfo?.businessName,
            primaryService: this.providerInfo?.service,
            businessPhone: this.providerInfo?.businessPhone,
            businessEmail: this.providerInfo?.businessEmail,
            website: this.providerInfo?.websiteUrl,
            contactMethods: this.acceptedContactMethods,
            contactForRate: this.providerInfo?.contactForRate || false,
            rate: this.providerInfo?.rate || 0,
            insured: this.providerInfo?.isInsured || false,
            paymentMethods: this.acceptedPaymentMethods,
            category: this.providerCategories.length > 0 ? this.providerCategories[0]?.toLowerCase() : null,
            specialties: this.providerInfo?.specialties || [],
            subCategories: this.providerSubCategories,
            introduction: this.providerInfo?.introduction,
            experience: this.providerInfo?.experience,
            services: this.providerInfo?.services,
            whyHire: this.providerInfo?.whyHire,
            instaUrl: this.providerInfo?.instaUrl,
            facebookUrl: this.providerInfo?.facebookUrl,
            linkedInUrl: this.providerInfo?.linkedInUrl,
            youTubeUrl: this.providerInfo?.youTubeUrl,
            tikTokUrl: this.providerInfo?.tikTokUrl,
            locations: this.workingLocations
        });

        this.setAllLocationsCheckboxState(this.workingLocations);
        this.initialFormValues = this.formGroup.getRawValue();
    }

    // Enable/disable the hourlyRate field based on contactForPricing value
    protected toggleRateField(contactForRate: boolean) {
        const rateControl = this.formGroup.get('rate');
        if (!contactForRate) {
            rateControl?.enable();  // Enable the hourly rate input
        } else {
            rateControl?.disable();  // Disable and reset the hourly rate input
            rateControl?.reset();
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    // Called by parent form to inquire if this component has any pending changes.
    hasChanges(): boolean {
        return this.formGroup.dirty
            && this.formGroup.valid
            && JSON.stringify(this.formGroup.getRawValue()) !== JSON.stringify(this.initialFormValues);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.formGroup.valid) {
            this.submitForm.emit();
        } else {
            this.formGroup.markAllAsTouched();  // Mark all controls as touched to show validation errors
        }
    }

    protected onDragOver(event: DragEvent) {
        event.preventDefault(); // Prevent default behavior to allow drop
        event.stopPropagation();
        console.log('Drag over event');
    }

    protected onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        console.log('Drag leave event');
    }

    protected onDrop(event: DragEvent, imageType = ImageType.Profile) {
        event.preventDefault();
        event.stopPropagation();

        this.profileImageType = imageType;

        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            this.initFile = files[0];
            this.showImageSelectionDlg = true;
        }
    }

    protected onBizFileSelected(event: Event, fileInput: HTMLInputElement) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            this.initFile = input.files[0];
            this.profileImageType = ImageType.BusinessProfile;
            this.showImageSelectionDlg = true;

            fileInput.value = "";
        }
    }

    protected onUploadBizFile() {
        this.bizFileInput.nativeElement.click();
    }

    protected onGalleryFileSelected(event: Event, fileInput: HTMLInputElement) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            this.initFile = input.files[0];
            this.profileImageType = ImageType.BusinessGallery;
            this.showImageSelectionDlg = true;

            fileInput.value = "";
        }
    }

    protected onUploadGalleryFile() {
        this.galleryFileInput.nativeElement.click();
    }

    protected onCancel(propagateEvent = true) {
        this.formGroup.reset(this.initialFormValues);

        if (propagateEvent) {
            this.broadcastService.cancelComponentChanges.next('BusinessProfileComponent');
        }
    }

    async onSaveProfileImage(blobInfo: CropperImageInfo) {
        const self = this;

        // Retrieve any old profile image references from the db.
        this.imageManager.getProfilePictureRefs(this.profileImageType)
            .subscribe({
                next: async (refs: ImageReference[]) => {
                    // Delete each legacy blob (from azure) and image reference (from the database).
                    for (let i = 0; i < refs.length; i++) {
                        // Delete the physical blob and its the database reference
                        try {
                            await self.blobService.deleteBlob(`${self.user.id}/${refs[i].fileName}`, () => {
                                self.imageManager.deleteImageReference(refs[i].id).subscribe();
                            });
                        }
                        catch (error: any) {
                            // Not ideal, but not fatal. Should never occur, but if it does we want to ignore it.
                            // TODO: Log this.
                            if (error?.message.includes('specified blob does not exist')) {
                                this.imageManager.deleteImageReference(refs[i].id).subscribe();
                            }
                        }
                    }

                    const uniqueBlobName = Util.generateGUID();

                    try {
                        // Upload the new image to azure blob storage
                        await self.blobService.uploadBlob(`${self.user.id}/${uniqueBlobName}`, blobInfo.blob, () => {
                            // If blob upload succeeds, add a db reference.
                            self.imageManager.addImageReference(new AddImageModel(uniqueBlobName, blobInfo.blobName, self.profileImageType))
                                .subscribe({
                                    next: response => {
                                        if (response.uri != null) {
                                            self.bizProfileImageUri = `${environment.ohhCdnUrl}/${response.uri}`;
                                            self.showImageSelectionDlg = false;
                                        }
                                    },
                                    error: err => {
                                        // TODO: Notify user of failure ??
                                        self.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: err }));
                                    }
                                })
                        });
                    }
                    catch (error) {
                        self.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: error }));
                    }
                    finally {
                        self.showImageCropperSpinner = false;
                    }

                },
                error: err => {
                    // TODO: Notify user of failure ??
                    this.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: err }));
                }
            })
    }

    async onSaveGalleryImage(blobInfo: CropperImageInfo) {
        const self = this;
        const uniqueBlobName = Util.generateGUID();

        try {
            // Upload the new image to azure blob storage
            await this.blobService.uploadBlob(`${self.user.id}/${uniqueBlobName}`, blobInfo.blob, () => {
                // If blob upload succeeds, add a db reference.
                this.imageManager.addImageReference(new AddImageModel(uniqueBlobName, blobInfo.blobName, self.profileImageType))
                    .subscribe({
                        next: response => {
                            if (response.uri != null) {
                                // this.bizProfileImageUri = `${environment.ohhCdnUrl}/${response.uri}`;
                                self.updateGalleryImagesArray(response);
                                self.showImageSelectionDlg = false;
                            }
                        },
                        error: err => {
                            // TODO: Notify user of failure ??
                            self.logger.handleError(new Error('Unable to save profile image to blob storage.', { cause: err }));
                        }
                    })
            });
        }
        catch (error) {
            self.logger.handleError(new Error('Unable to save gallery image to blob storage.', { cause: error }));
        }
        finally {
            self.showImageCropperSpinner = false;
        }

    }

    protected confirmImageDeletion(event: any, image: OhhImageInfo) {
        this.confirmationService.confirm({
            header: 'Confirm Deletion',
            message: 'Are you sure you want to delete this image?',
            acceptIcon: 'none',
            rejectIcon: 'none',
            icon: 'pi pi-question-circle',
            accept: () => {
                this.deleteGalleryImage(image).then();
            },
            reject: () => {
                console.log();
            }
        })
    }

    protected async deleteGalleryImage(image: OhhImageInfo) {
        const self = this;

        const nameBits = image.blobName.split('/');
        const fileName = nameBits[nameBits.length - 1];

        try {
            await this.blobService.deleteBlob(`${self.user.id}/${fileName}`, () => {
            });
        }
        catch (ex) {
            console.log();
            // TODO: Log
        }
        finally {
            // Ensure image references are removed even if physical image deletion fails.
            this.imageManager.deleteImageReference(image.id).subscribe();
            this.galleryImages = self.galleryImages.filter(i => i?.id != image.id);
        }
    }

    protected onAllLocationsClick(event: any) {
        console.log();
    }

    protected onAllLocationsChange(event: any) {
        if (event.value == false || event.value == null) {
            this.areAllLocationsSelected = null;
            this.toggleAllLocations(false);
            return;
        }

        this.toggleAllLocations(true);
    }

    protected onSubmitBackgroundCheck() {
        this.router.navigate([`/background-check`], { queryParams: { preferred: true } });
    }

    protected toggleImagePreview(isOpen: boolean) {
        this.isImagePreviewOpen = isOpen;
    }
    // =========================================================================================================================================================
    // helper Methods
    // =========================================================================================================================================================

    private setAllLocationsCheckboxState(selectedLocations: string[]) {
        if (this.locations?.length == selectedLocations?.length) {
            this.areAllLocationsSelected = true;
        } else if (selectedLocations?.length > 0) {
            this.areAllLocationsSelected = false;
        } else {
            this.areAllLocationsSelected = null;
        }
    }

    private toggleAllLocations(enableAll: boolean) {
        const selectedLocations = enableAll ? this.locations.map(i => i.id) : [];

        const locationControl = this.formGroup.get('locations');
        locationControl.setValue(selectedLocations, { emitEvent: true });

        if (JSON.stringify(this.formGroup.getRawValue()) !== JSON.stringify(this.initialFormValues)) {
            locationControl.markAsDirty();
        } else {
            locationControl.markAsPristine();
        }
    }

    private updateGalleryImagesArray(imageReference: ImageReference = null) {
        this.galleryImages = this.galleryImages.filter(i => i != null);
        if (this.galleryImages.length == this.MaxGalleryImages) { return; }

        if (imageReference != null) { this.galleryImages.push({ ...imageReference }); }

        if (this.galleryImages.length < this.MaxGalleryImages) {
            this.galleryImages.push(null);
        }
    }

    private getComponentData() {
        const sources: Observable<any>[] = [
            this.imageManager.getBizProfilePictureUri().pipe(take(1)),
            this.imageManager.getProfilePictureRefs(ImageType.BusinessGallery).pipe(take(1)),
            this.categoryManager.getCategories().pipe(take(1)),
            this.paymentMethodManager.getPaymentMethods().pipe(take(1)),
            this.contactMethodManager.getContactMethods().pipe(take(1)),
            this.locationManager.getLocations().pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                const selectedCategoryId = this.formGroup.get('category').value;
                this.selectedCategory = this.serviceCategories.find(c => c.id == selectedCategoryId)
            }))
            .subscribe({
                next: ([bizProfileUri, galleryImages, serviceCategories, paymentMethods, contactMethods, locations]) => {
                    this.bizProfileImageUri = bizProfileUri != null ? `${environment.ohhCdnUrl}/${bizProfileUri}` : this.placeholderImageUrl;
                    this.galleryImages = galleryImages;
                    this.serviceCategories = serviceCategories.sort((a: OhhServiceCategory, b: OhhServiceCategory) => a.name.localeCompare(b.name));
                    this.paymentMethods = paymentMethods.sort((a: OhhPaymentMethod, b: OhhPaymentMethod) => a.name.localeCompare(b.name));
                    this.contactMethods = contactMethods.sort((a: OhhContactMethod, b: OhhContactMethod) => a.name.localeCompare(b.name));
                    this.locations = locations.sort((a: OhhProviderLocation, b: OhhProviderLocation) => a.name.localeCompare(b.name));
                }, error: err => {
                    this.bizProfileImageUri = null;
                    this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                }
            });
    }

    protected isFormDirtyAndValid(): boolean {
        return this.parentFormGroup?.dirty && this.parentFormGroup?.valid;
    }

    private setBackgroundCheckStatus() {
        this.showBgCheckBtn = false;
        this.bgCheckStatus = null;

        switch (this.user.backgroundCheckStatus) {
            case BackgroundCheckStatus.NotStarted:
            case BackgroundCheckStatus.NotCompleted:
                this.showBgCheckBtn = true;
                break;

            case BackgroundCheckStatus.Complete:
                this.bgCheckStatus = !this.user.clearChecksIsFlagged ? 'STRINGS.VERIFIED' : 'STRINGS.FLAGGED';
                this.bgStatusColor = !this.user.clearChecksIsFlagged ? '#74c365' : 'darkorange';
                break;

            case BackgroundCheckStatus.Pending:
                this.bgCheckStatus = 'STRINGS.PENDING';
                break;
        }
    }

    // =========================================================================================================================================================
    // Field Validation
    // =========================================================================================================================================================

    protected fieldIsInvalid(fieldName: string) {
        return this.formGroup.get(fieldName).invalid && this.formGroup.get(fieldName).touched && this.formGroup.get(fieldName).dirty;
    }

    // protected readonly onchange = onchange;
}
