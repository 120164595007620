import MessageSeverity = OhhEnums.MessageSeverity;
import PaymentResponseType = OhhEnums.PaymentResponseType;

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BackgroundCheckManagerService } from '../../../api/background_check/background-check-manager.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ClearChecksNewOrder } from '../../../api/background_check/models/clearChecksNewOrder';
import { ComponentBase } from '../../../shared/misc/component-base';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';
import { OhhUser } from '../../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../../api/ohh-user/ohh-user-manager.service';

declare const ClearChecksApplicantWidget: any;

@Component({
    selector: 'ohh-background-check',
    templateUrl: './background-check.component.html',
    styleUrl: './background-check.component.less'
})
export class BackgroundCheckComponent extends ComponentBase implements OnInit {

    protected paymentResponseType: PaymentResponseType;
    protected responseText: string;
    protected isBusy = false;
    protected reportKey: string;
    protected user: OhhUser;

    private isPreferred = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private backgroundCheckManager: BackgroundCheckManagerService,
                protected override broadcastService: BroadcastService,
                private messageService: OhhMessageService,
                private userManager: OhhUserManagerService,
                private route: ActivatedRoute,
                private router: Router,
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.isPreferred = this.route.snapshot.queryParamMap.get('preferred') == 'true';

        this.user = this.userManager.ohhUser;
        this.reportKey = this.userManager.ohhUser.clearChecksReportKey;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onOrderCheck() {
        if (this.user == null) { return; }

        this.isBusy = true;

        if (this.reportKey != null && this.reportKey != '') {
            this.openApplicantWidget(this.reportKey);
            return;
        }

        const order = new ClearChecksNewOrder({
            applicant_emails: [this.user.email]
        });

        this.backgroundCheckManager.createNewOrder(order)
            .subscribe({
                next: (order) => {
                    this.reportKey = order?.applicants[0].report_key;
                    this.openApplicantWidget(this.reportKey)
                },
                error: (error) => {
                    this.isBusy = false;
                    this.messageService.showMessage(
                        "Background Check Initialization Error",
                        "Unable to initialize background check. Please try again.",
                        MessageSeverity.Error);
                }
            })
    }

    openApplicantWidget(reportKey: string): void {
        // Use the global ClearChecksApplicantWidget object
        const applicantWidget = ClearChecksApplicantWidget.init({
            reportKey: reportKey,

            onSuccess: (data: any) => {
                this.messageService.showMessage(
                    "Success",
                    "Your background check was successfully submitted. Redirecting to Provider Account.",
                    MessageSeverity.Success);

                setTimeout(() => this.router.navigate([`/providers/account`], { queryParams:  { preferred: !this.isPreferred }}), 5000);
            },

            onClose: () => {
                console.log('Widget was closed');
            },

            onError: (error: any) => {
                this.messageService.showMessage(
                    "Error",
                    "We encountered an error while submitting your background check. Please try again.",
                    MessageSeverity.Error);

            },
        });

        applicantWidget.open();
        setTimeout(() => this.isBusy = false, 3000);
    }
}
