<div id="ol-sign-up" class="ol-page-container">
    <ohh-full-vw-constrained-container [isPadded]="true">
        <ohh-section-header>
            <div class="ol-sh-heading">
                <span>Become a <strong>Customer</strong></span>
            </div>
            <div class="ol-sh-sub-heading">
                <strong>Create Your FREE Account</strong>
            </div>
            <div id="ol-sub-text">
                <span>As a customer you will enjoy a variety of services, including exclusive classified ads where you can find the perfect
                match for any job.</span>
            </div>
        </ohh-section-header>

        <div id="ol-sign-up-wrapper">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div id="ol-sign-up-container">

                    <div id="ol-name-container">
                        <div class="ol-labeled-input-text">
                            <label for="firstName" class="prevent-select">First name*</label>
                            <input pInputText id="firstName" formControlName="firstName">
                            <div class="ol-field-error" *ngIf="fieldIsInvalid('firstName')">
                                <span *ngIf="form.controls.firstName?.errors?.['required']">First Name is required.</span>
                            </div>
                        </div>
                        <div class="ol-labeled-input-text">
                            <label for="lastName" class="prevent-select">Last name*</label>
                            <input pInputText id="lastName" formControlName="lastName">
                            <div class="ol-field-error" *ngIf="fieldIsInvalid('lastName')">
                                <span *ngIf="form.controls.lastName?.errors?.['required']">Last Name is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="ol-labeled-input-text">
                        <label for="email" class="prevent-select">Email Address*</label>
                        <input pInputText id="email" type="email" formControlName="email">
                        <div class="ol-field-error" *ngIf="fieldIsInvalid('email')">
                            <span *ngIf="form.controls.email?.errors?.['required']">Email is required.</span>
                            <span *ngIf="form.controls.email?.errors?.['email']">Email address is invalid.</span>
                        </div>
                    </div>

                    <div id="ol-set-password-container">
                        <div class="ol-labeled-input-text">
                            <label for="password" class="prevent-select">Password*</label>
                            <input pInputText id="password" type="password" formControlName="password">
                            <div class="ol-field-error" *ngIf="fieldIsInvalid('password')">
                                <span *ngIf="form.controls.password?.errors?.['required']">Password is required.</span>
                                <span *ngIf="form.controls.password?.errors?.['minlength']">Password must be at least 6 characters long.</span>
                            </div>
                        </div>
                        <div class="ol-labeled-input-text">
                            <label for="password" class="prevent-select">Confirm Password*</label>
                            <input pInputText id="confPassword" type="password" formControlName="confPassword">
                            <div class="ol-field-error" *ngIf="fieldIsInvalid('confPassword')">
                                <span *ngIf="form.controls.confPassword?.errors?.['required']">Confirm Password is required.</span>
                                <span *ngIf="form.controls.confPassword?.errors?.['minlength']">Password must be at least 6 characters long.</span>
                            </div>
                        </div>
                    </div>

                    <div class="ol-form-error-box">
                        <span *ngIf="passwordMismatch && (!fieldIsInvalid('password') && fieldIsTouchedAndDirty('password')) && (!fieldIsInvalid('confPassword') && fieldIsTouchedAndDirty('confPassword'))">
                            Passwords do not match.
                        </span>
                    </div>

                    <div class="ol-field-checkbox">
                        <p-checkbox
								id="terms"
                                formControlName="terms"
                                inputId="terms"
                                binary="true"
                        ></p-checkbox>
						<label for="terms" class="ml-2">
                            <span class="prevent-select">{{ 'PHRASE.I_AGREE_TO_THE' | translate }}</span>
                            <span class="ol-pseudo-link" (click)="gotoTerms()"> {{ 'CASE_SENSITIVE.TERMS_OF_USE' | translate }}</span>
                            <span class="prevent-select"> {{ 'STRINGS.AND' | translate | lowercase }}</span>
                            <span class="ol-pseudo-link" (click)="gotoPrivacy()"> {{ 'CASE_SENSITIVE.PRIVACY_POLICY' | translate }} </span>
                        </label>
                        <div class="ol-field-error" *ngIf="fieldIsInvalid('terms')">
                            <span>{{ 'PHRASE.YOU_MUST_AGREE_TO_TERMS' | translate }}</span>
                        </div>
                    </div>

                    <div id="ol-btn-container">
                        <button type="submit" class="ol-green-button" [disabled]="form.invalid">{{ 'CASE_SENSITIVE.CREATE_ACCOUNT' | translate }}</button>
                    </div>

                </div>

            </form>

			<ohh-waiting-indicator *ngIf="isRegistering" [backgroundColor]="'#c8c8c81A'" [blurBackground]="true"></ohh-waiting-indicator>
        </div>

    </ohh-full-vw-constrained-container>
</div>
