import MessageSeverity = OhhEnums.MessageSeverity;

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { finalize, switchMap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { FormValidationService } from '../../common/services/form-validation.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { RegisterUserRequest } from '../../api/auth/models/register-user-request.model';
import { RegisterUserResponse } from '../../api/auth/models/register-user-response.model';

@Component({
    selector: 'ohh-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrl: './user-registration.component.less'
})
export class UserRegistrationComponent implements OnInit {

    protected isProviderSignup = false;
    protected isRegistering = false;

    form = new FormGroup({
        firstName: new FormControl('', {
            validators: [Validators.required],
        }),
        lastName: new FormControl('', {
            validators: [Validators.required],
        }),
        email: new FormControl('', {
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', {
            validators: [Validators.required, Validators.minLength(6)]
        }),
        confPassword: new FormControl('', {
            validators: [Validators.required, Validators.minLength(6)]
        }),
        terms: new FormControl(false, {
            validators: [Validators.requiredTrue]
        })
    });
    //     confPassword: new FormControl('', {
    //         validators: [Validators.required]
    //     })
    // }, { validators: passwordMatchValidator });

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private logger: ErrorLoggingService,
                private formValidationService: FormValidationService,
                private messageService: OhhMessageService,
                private ohhUserManager: OhhUserManagerService,
                private route: ActivatedRoute,
                private router: Router
    ) {}

    ngOnInit() {
        this.isProviderSignup = this.route.snapshot.queryParamMap.get('isProviderSignup') == 'true';

        // Add custom validator(s)
        this.form.setValidators(this.formValidationService.passwordMatchValidator());
        this.form.updateValueAndValidity();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.form.invalid || this.form.controls.email.invalid || this.form.controls.password.invalid || this.form.controls.terms.invalid)
        {
            return;

            // TODO: Figure out our error notification strategy.
        }

        this.isRegistering = true;
        const user = new RegisterUserRequest(this.form.controls.email.value, this.form.controls.password.value, this.isProviderSignup);

        this.authManager.registerUser(user)
            .pipe(
                catchError(err => {
                    this.isRegistering = false;
                    throw err;
                }),
                switchMap((response: RegisterUserResponse) => {
                    const controls = this.form.controls;

                    const ohhUser = new OhhUser({
                        id: response.id,
                        email: response.userName,
                        firstName: controls.firstName.value,
                        lastName: controls.lastName.value,
                    });

                    return this.ohhUserManager.createUser(ohhUser)
                        .pipe(finalize(() => this.isRegistering = false));
                }))
            .subscribe({
                next: (ohhUser: OhhUser) => {
                    this.router.navigate(['/verify-email-sent'], { queryParams: { email: ohhUser.email } });
                },
                error: err => {
                    this.messageService.showMessage("Error Creating User", err?.error?.Error_1[0], MessageSeverity.Error);
                    this.logger.handleError(new Error('Error creating user.', { cause: err }));
                }
            })
    }

    protected gotoPrivacy() {
        window.open(`${environment.ohhClientUrl}/help-center/privacy-policy`, '_blank')
    }

    protected gotoTerms() {
        window.open(`${environment.ohhClientUrl}/help-center/terms`, '_blank')
    }

    // =========================================================================================================================================================
    // Filed Validation
    // =========================================================================================================================================================

    protected get passwordMismatch() {
        return this.form.errors?.['passwordMismatch'];
    }

    protected fieldIsInvalid(fieldName: string) {
        return this.form.get(fieldName).invalid && this.form.get(fieldName).touched && this.form.get(fieldName).dirty;
    }

    protected fieldIsTouchedAndDirty(fieldName: string) {
        return this.form.get(fieldName).touched || this.form.get(fieldName).dirty;
    }

}
