import ImageType = OhhEnums.ImageType;
import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CropperImageInfo } from './models/cropper-image-info.model';
import { ImageAssetService } from '../../../common/services/image-asset.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';
import { UploadFileResult } from '../../../common/services/models/upload-file-result.model';

@Component({
    selector: 'ohh-image-cropper',
    templateUrl: './ohh-image-cropper.component.html',
    styleUrl: './ohh-image-cropper.component.less'
})
export class OhhImageCropperComponent implements OnInit {

    @Output() public save = new EventEmitter<CropperImageInfo>();
    @Output() public cancel = new EventEmitter();

    @Input() public currentImageUri: string;
    @Input() initImage: File;
    @Input() imgRatioNumerator = 1;
    @Input() imgRatioDenominator = 1;
    @Input() containWithinAspectRatio = false
    @Input() imageType: ImageType = ImageType.BusinessGallery;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    file: File;

    selectedFile: File;
    croppedBlob: Blob;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private imageAssetService: ImageAssetService,
                private messageService: OhhMessageService
    ) {}

    ngOnInit() {
        if (this.containWithinAspectRatio == true) {
            this.imgRatioNumerator = 1;
            this.imgRatioDenominator = 1;
        }
    }

    // blobToFile(blob: Blob, fileName: string): File {
    //     return new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
    // }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected fileChangeEvent(event: any): void {
        this.initImage = null;
        this.imageChangedEvent = event;
        this.selectedFile = event.target.files[0];
    }

    protected imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.objectUrl;
        this.croppedBlob = event.blob;
    }

    protected loadImageFailed(event: any) {
        this.messageService.showMessage(
            'PHRASE.UNABLE_TO_LOAD_IMAGE',
            'PHRASE.SELECTED_IMAGE_COULD_NOT_BE_LOADED',
            MessageSeverity.Error
        );
    }

    protected saveImage() {
        const fileName = this.initImage == null ? this.selectedFile.name : this.initImage.name;

        this.imageAssetService.getResizedFileFromBlob(this.croppedBlob, this.imageType)
            .subscribe({
                next: (result: UploadFileResult) => {
                    this.save.emit(new CropperImageInfo(fileName, result.fileOrUrl));
                },
                error: err => {
                    this.messageService.showMessage(
                        'PHRASE.UNABLE_TO_SAVE_IMAGE',
                        'PHRASE.UNABLE_TO_SAVE_IMAGE_DETAILS',
                        MessageSeverity.Error
                    );
                }
            })
    }

    protected onCancel() {
        this.cancel.emit();
    }
}
