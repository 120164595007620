import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { environment } from '../../../../environments/environment';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { ImageManagerService } from '../../../api/image/image-manager.service';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { OhhUser } from '../../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../../api/ohh-user/ohh-user-manager.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'ohh-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrl: './menu-bar.component.less'
})
export class MenuBarComponent implements OnInit {
    @ViewChild('op') op: OverlayPanel;
    @ViewChild('avatarMenu') avatarMenu: Menu;

    private _isUserLoggedIn = false;
    protected get isUserLoggedIn(): boolean {
        return this._isUserLoggedIn;
    }

    protected set isUserLoggedIn(value: boolean) {
        this._isUserLoggedIn = value;
        this.setAvatarLabel();
    }

    protected showJoinUsPanel = false;
    protected loginLabel: string;
    protected avatarMenuItems: MenuItem[];
    protected avatarLabel: string;
    protected avatarImageUri: string;

    private ohhUser: OhhUser;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private logger: ErrorLoggingService,
                private imageManager: ImageManagerService,
                private ohhUserManager: OhhUserManagerService,
                private router: Router,
                private translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.ohhUserManager.userLogInStateChanged
            .pipe(untilDestroyed(this))
            .subscribe((ohhUser: OhhUser) => {
                this.ohhUser = ohhUser;
                this.isUserLoggedIn = this.ohhUser != null;
                this.getProfilePicture();
            });

        this.broadcastService.profilePictureChanged
            .pipe(untilDestroyed(this))
            .subscribe((data: { uri: string}) => this.avatarImageUri = data.uri )

        this.broadcastService.providerStatusChanged
            .pipe(untilDestroyed(this))
            .subscribe((isProvider: boolean) => this.ohhUser.isProvider = isProvider )

        this.ohhUser = this.ohhUserManager.ohhUser;
        this.isUserLoggedIn = this.ohhUserManager.isUserLoggedIn;
        this.getProfilePicture();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onJoinUs() {
        this.showJoinUsPanel = !this.showJoinUsPanel;
    }

    protected onSignIn(event: any) {
        this.router.navigate(['/log-in']);
        this.broadcastService.hideMobileMenu.next(null);
    }

    protected onAvatarClick(event: any) {
        if (this.ohhUser == null) return;

        this.avatarMenuItems = [
            { separator: true },
            { label: this.translateService.instant('CASE_SENSITIVE.LOG_OUT'), command: this.logout.bind(this) }
        ];

        const menuItem = this.ohhUser.isProvider
            ? { label: this.translateService.instant('STRINGS.ACCOUNT'), command: this.navigateToProviderAccount.bind(this) }
            : { label: this.translateService.instant('STRINGS.ACCOUNT'), command: this.navigateToCustomerProfile.bind(this) };

        this.avatarMenuItems.splice(0, 0, menuItem)

        this.avatarMenu.toggle(event);

        // Avoid browser console error by overriding aria-hidden attribute of menu items.
        setTimeout(() => this.modifyAriaHidden());
    }

    protected onNewCustomer(event: any) {
        this.op.hide();
        this.router.navigate(['/register']);
        this.broadcastService.hideMobileMenu.next(null);
    }

    protected onNewProvider(event: any) {
        this.op.hide();
        this.router.navigate(['/pro-sign-up']);
        this.broadcastService.hideMobileMenu.next(null);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    modifyAriaHidden() {
        // Access the DOM elements generated by PrimeNG's p-menu
        // const menuItems: HTMLElement[] = this.avatarMenu.el.nativeElement.querySelectorAll('.p-menuitem-link');

        const menuItems = document.querySelectorAll('.p-menuitem-link');

        // Modify the aria-hidden attribute for each menu item link
        menuItems.forEach(item => {
            if (item.hasAttribute('aria-hidden')) {
                item.setAttribute('aria-hidden', 'false'); // Set aria-hidden to false or remove it
            }
        });
    }

    private setLoginLabel() {
        this.loginLabel = this.isUserLoggedIn ? 'CASE_SENSITIVE.LOG_OUT' : 'CASE_SENSITIVE.LOG_IN'
    }

    private logout() {
        this.ohhUserManager.logout();
        // this.ohhMessageService.showMessage("Logged out", "You have successfully logged out.", MessageSeverity.Success);
        this.router.navigate(['/']);
    }

    private setAvatarLabel(): void {
        if (this.ohhUser == null) this.avatarLabel = '';

        this.avatarLabel = `${this.ohhUser?.firstName?.substring(0, 1) || ''}${this.ohhUser?.lastName?.substring(0, 1) || ''}`;
    }

    private navigateToProviderAccount() {
        this.router.navigate(['/providers/account']);
        this.broadcastService.hideMobileMenu.next(null);
    }

    private navigateToCustomerProfile(event: any) {
        this.router.navigate(['/customer/profile']);
        this.broadcastService.hideMobileMenu.next(null);
    }

    private getProfilePicture() {
        if (!this.isUserLoggedIn) return;

        this.imageManager.getProfilePictureUri()
            .subscribe({
                next: (uri: string) => {
                    this.avatarImageUri = uri != null ? `${environment.ohhCdnUrl}/${uri}` : null;
                }, error: err => {
                    this.avatarImageUri = null;
                    this.logger.handleError(new Error('Unable to retrieve profile picture.', { cause: err }));
                }
            });
    }

}
