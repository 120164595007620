import { Component, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { OhhServiceCategory } from '../../../api/service-category/models/ohh-service-category.model';
import { Router } from '@angular/router';
import { ServiceCategoryManager } from '../../../api/service-category/service-category-manager.service';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'ohh-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrl: './mega-menu.component.less'
})
export class MegaMenuComponent implements OnInit {

    @Input() hideHeader = false;
    @Input() nonModalUsage = false;

    protected categories: OhhServiceCategory[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
        private logger: ErrorLoggingService,
        private router: Router,
        private serviceCategoryManager: ServiceCategoryManager
    ) { }

    ngOnInit(): void {
        this.getComponentData();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCategoryClick(category: OhhServiceCategory): void {
        const parent = this.categories.find(c => c.subCategories.some(subCategory => subCategory.id === category.id));
        this.router.navigate(['/services'], { queryParams: { category: parent?.id, subCategory: category.id } });
        this.broadcastService.showMegaMenu.next(false);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {
        this.serviceCategoryManager.getCategories()
            .pipe(take(1))
            .subscribe({
                next: (categories: OhhServiceCategory[]) => {

                    // Note: We want all sub-categories to be alphabetically sorted.
                    this.categories = categories?.map(category => ({
                        ...category,
                        subCategories: category.subCategories
                            ? [...category.subCategories].sort((a, b) => a.name.localeCompare(b.name))
                            : []
                    }));
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data: service categories.', { cause: err }));
                }
            })
    }

}
