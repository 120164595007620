import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from './common/services/broadcast.service';
import { DeviceManagerService } from './common/services/device-manager.service';
import { environment } from '../environments/environment';
import { ErrorLoggingService } from './common/services/error-logging.service';
import { PrimeNGConfig } from 'primeng/api';
import { ServiceCategoryManager } from './api/service-category/service-category-manager.service';

@UntilDestroy()
@Component({
    selector: 'ohh-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    @HostBinding('class') class = 'toro-root';
    @ViewChild('scrollContainer') scrollContainer: ElementRef;

    @HostListener('window:resize')
    onResize() {
        // Set up the host listener to listen for resize events to trigger hiding/showing
        // of hamburger menu and responsive design of navigation pane.
        this.deviceManager.windowResize.next(null);
    }

    //protected showFooter = true;
    protected isShowingHeader = true;
    protected isShowingMegaMenu = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private categoryManager: ServiceCategoryManager,
                protected deviceManager: DeviceManagerService,
                private logger: ErrorLoggingService,
                private primengConfig: PrimeNGConfig,
                private router: Router,
    ) {}

    ngOnInit(): void {
        this.addClearChecksScriptTag();

        this.primengConfig.ripple = true;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.scrollToTop();
            }
        });

        this.broadcastService.showMegaMenu
            .pipe(untilDestroyed(this))
            .subscribe((show: boolean) => {
                this.isShowingMegaMenu = show;
            });

        this.broadcastService.scrollToTop
            .pipe(untilDestroyed(this))
            .subscribe(() => this.scrollToTop())

        // Moved to AppInitializer.
        // this.ohhUserManager.getUserByToken().subscribe();

        this.preloadData();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onScroll(event: any) {
        // this.toggleHeader(event.target);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private preloadData() {
        this.categoryManager.getCategories()
            .subscribe({
                next: results => {},
                error: err => {
                    this.logger.handleError(new Error('Unable to load service categories.', { cause: err }));
                }
            });
    }

    // private checkForScrollbar() {
    //     const element = document.getElementById('ol-main-body-scroll-container');
    //     this.hasVertScrollbar = element != null && (element.scrollHeight > element.clientHeight);
    //     this.toggleHeader(<HTMLElement>element);
    // }
    //
    // toggleHeader(el: HTMLElement) {
    //     if (!this.hasVertScrollbar) {
    //         this.isShowingHeader = true;
    //     } else {
    //         // If the footer is already showing, and we've scrolled farther down the page, no need to reevaluate.
    //         if (this.isShowingHeader && this.prevScrollTop > el.scrollTop) {
    //             return;
    //         }
    //
    //         // this.isShowingHeader = el.scrollTop + el.clientHeight + this.ANDROID_DYNAMIC_FOOTER_PAD >= el.scrollHeight;
    //         this.isShowingHeader = el.scrollTop < this.hideScrollbarHeight;
    //         this.prevScrollTop = el.scrollTop;
    //     }
    //
    //     // this.broadcastService.showFooter.next(this.isShowingHeader);
    // }

    private scrollToTop(): void {
        // Change behavior to smooth if we want to animate the scroll. Might not work for large categories.
        this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'auto' });
    }

    private addClearChecksScriptTag(): void {
        const script = document.createElement('script');
        script.src = (environment.name == 'prod') ? 'https://app.clearchecks.com/js/widgets/applicant-form-widget.js' : 'https://sandbox.clearchecks.com/js/widgets/applicant-form-widget.js'
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
    }
}
