import ImageType = OhhEnums.ImageType;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CropperImageInfo } from '../image-cropper/models/cropper-image-info.model';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';

@Component({
    selector: 'ohh-image-cropper-dlg',
    templateUrl: './image-cropper-dlg.component.html',
    styleUrl: './image-cropper-dlg.component.less'
})
export class ImageCropperDlgComponent implements OnInit {

    @Output() save = new EventEmitter<CropperImageInfo>();
    @Output() cancel = new EventEmitter();

    @Output() showSpinnerChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _showSpinner = false;
    @Input() set showSpinner(value: boolean) {
        if (value == this._showSpinner) { return; }

        this._showSpinner = value;
        this.showSpinnerChange.emit(value);
    }

    get showSpinner(): boolean {
        return this._showSpinner;
    }

    @Input() title = 'CASE_SENSITIVE.SELECT_IMAGE';
    @Input() currentImageUri: string;
    @Input() initImage: File;
    @Input() imgRatioNumerator = 1;
    @Input() imgRatioDenominator = 1;
    @Input() containWithinAspectRatio = false;
    @Input() imageType: ImageType = ImageType.BusinessGallery;      // Used to set image resize size;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    ngOnInit() {
        this.showSpinner = false;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSave(imageInfo: CropperImageInfo) {
        this.showSpinner = true;
        this.save.emit(imageInfo);
    }

    protected onCancel() {
        this.cancel.emit();
    }
}
